import { getIdTags } from "@/api/auth";
import { validDomain } from "@/api/valid";
import { clearCache, getCookie } from "@/service/cookie";
import axios from "axios";
import { defineStore } from "pinia";

export const useCommonStore = defineStore({
  id: "common",
  state: () => ({
    periodType: "beforeEvent", // beforeEvent, onEvent, eventEnd
    round: 1,
    crmClient: "",
    crmLoginUrl: "",
    crmHomeUrl: "",
    crmResetPasswordUrl: "",
    isRedirect: false,
    status1: "ended",
    status2: "ended",
    agentLocation: "cn",
    totalAmountClaimed: 0,
    annualPrizeCode: "",
    modal: {
      // MainModal
      isOpen: false,
      name: "",
      packetOpen: false,
      receiveJudgment: false,
    },
    rewardAmount: "",
    rewardType: "",
    displayFloatingButton: false,
    displayMoney: false,
    currentEvent: "iboffer2025",
    loading: false,
    error: null,
    previousEventName: "IB6Q4",
  }),
  actions: {
    setPeriodType(periodType) {
      this.periodType = periodType;
    },
    setStatus(s1, s2) {
      this.status1 = s1;
      this.status2 = s2;
    },
    setRound(round) {
      this.round = round;
    },
    setCrmClient(url) {
      this.crmClient = url;
    },
    setCrmLoginUrl(url: string) {
      this.crmLoginUrl = url;
    },
    setCrmHomeUrl(url: string) {
      this.crmHomeUrl = url;
    },
    setCrmResetPasswordUrl(url: string) {
      this.crmResetPasswordUrl = url;
    },
    setTotalAmountClaimed(amount: number) {
      this.totalAmountClaimed = amount;
    },
    setIsRedirect(t) {
      this.isRedirect = t;
    },
    openModal(name) {
      if (name == "Packet") {
        if (this.modal.receiveJudgment == true) {
          this.modal.isOpen = true;
          this.modal.name = name;
        }
        return;
      } else {
        this.modal.isOpen = true;
        this.modal.name = name;
      }
    },
    closeModal() {
      this.modal.isOpen = false;
      this.modal.packetOpen = false;
      setTimeout(() => {
        this.modal.name = "";
      }, 300);
    },
    setPacketOpen() {
      this.modal.packetOpen = true;
    },
    setReceiveJudgment() {
      this.modal.receiveJudgment = true;
    },
    setRewardAmount(data) {
      this.rewardAmount = data;
    },
    setRewardType(data) {
      this.rewardType = data;
    },
    setDisplayFloatingButton() {
      this.displayFloatingButton = true;
    },
    setHideFloatingButton() {
      this.displayFloatingButton = false;
    },
    setDisplayMoneyTrue() {
      this.displayMoney = true;
    },
    setDisplayMoneyFalse() {
      this.displayMoney = false;
    },
    setAgentLocation(data: string) {
      this.agentLocation = data.toLowerCase();
    },
    async fetchCRMIdTags() {
      this.loading = true;
      this.error = null;
      try {
        const { hostname, pathname } = window.location;
        const { data } = await getIdTags({ hostname, path: pathname });

        if (data.code == 0) {
          const queryString = new URLSearchParams(data.data).toString();
          this.setCrmLoginUrl(`${this.crmLoginUrl}?${queryString}`);
        }
        console.log("crmLoginUrl", this.crmLoginUrl);
        console.log("unable get token data from backend");
        window.location.href = this.crmLoginUrl;
      } catch (err) {
        this.error = err.message || "Failed to fetch data";
      } finally {
        this.loading = false;
      }
    },
    setPreviousEventName(eventName: string) {
      this.previousEventName = eventName;
    },

    async checkCRMURL() {
      const sessionId = getCookie("sessionId");
      const refreshToken = getCookie("refreshToken");

      // Base URL configurations for different environments
      const baseUrlConfig = {
        localhost: "https://v5-crm-client-uat.zeusenv.com",
        // dev: "https://dev-crm-nginx.finpoints.tech",
        dev: "https://v5-crm-client-dev.finpoints.tech",
        stg: "https://stg-crm-nginx.finpoints.tech",
        uat: "https://v5-crm-client-uat.zeusenv.com",
        pre: "https://demotestuser.doo-666.com",
        default: "https://www.dooprimep1.com",
      };
      const ibBaseUrlConfig = {
        localhost: "https://v5-crm-client-uat.zeusenv.com",
        // dev: "https://dev-crm-nginx.finpoints.tech",
        dev: "https://v5-crm-ib-dev.finpoints.tech",
        stg: "https://v5-crm-ib-stg.finpoints.tech",
        uat: "https://v5-crm-ib-uat.zeusenv.com",
        pre: "https://ib.demotestuser.doo-666.com",
        default: "https://www.dooprimep1.com",
      };

      // Helper function to generate URLs
      const generateURLs = (baseUrl) => ({
        loginUrl: `${baseUrl}/login`,
        homeUrl: `${baseUrl}/`,
        resetPasswordUrl: `${baseUrl}/recovery`,
      });

      // Determine the environment based on the current host
      const host = window.location.host;
      const environment =
        Object.keys(baseUrlConfig).find((env) => host.includes(env)) ||
        "default";

      // Get the base URL for the current environment
      let baseUrl = sessionId
        ? ibBaseUrlConfig[environment]
        : baseUrlConfig[environment];
      const rawRedirectLink = getCookie("redirectLink");

      const crmRedirectLink = rawRedirectLink
        ? decodeURIComponent(rawRedirectLink)
        : null;

      // If crmRedirectLink exists in localhost, update baseUrl dynamically
      if (crmRedirectLink != null) {
        baseUrl = `https://${new URL(crmRedirectLink).hostname}`;

        // const preFlightUrl = await fetch(baseUrl, {
        //   headers: { "Access-Control-Allow-Origin": "*" },
        //   mode: "no-cors",
        // });

        // if (preFlightUrl.status !== 200) {
        //   clearCache();
        //   // Set success flag and break out of loop
        // }
      } else if (environment === "default") {
        const data = await validDomain();
        baseUrl = data;
      }

      // Generate final URLs
      const { loginUrl, homeUrl, resetPasswordUrl } = generateURLs(baseUrl);

      // Set URLs in the store
      this.setCrmLoginUrl(loginUrl);
      this.setCrmHomeUrl(homeUrl);
      this.setCrmResetPasswordUrl(resetPasswordUrl);

      // Append sessionId and refreshToken if available
      if (sessionId && refreshToken) {
        this.setCrmHomeUrl(
          `${homeUrl}?sessionId=${sessionId}&refreshToken=${refreshToken}`
        );
      }
    },
  },
});

<template>
    <div class="modal-content RedeemSuccess2">
        <img :src="imageUrl + 'icon-redeem-success.png'" />
        <p class="title">{{ $t("ib6.modal.successTitle") }}</p>
        <p v-if="commonStore.annualPrizeCode == '' && commonStore.annualPrizeCode == null" class="content">USD {{
            commonStore.totalAmountClaimed }}</p>
        <div v-if="commonStore.annualPrizeCode != '' && commonStore.annualPrizeCode != null" class="content_wrapper">
            <p class="content">USD {{ commonStore.totalAmountClaimed }}</p>
            <p>&</p>
            <p class="content">{{ $t(`ib6.modal.reward${commonStore.annualPrizeCode}`) }}</p>
        </div>
        <p v-if="commonStore.annualPrizeCode != '' && commonStore.annualPrizeCode != null">{{
            $t('ib6.modal.annualContact')
        }}</p>
    </div>
</template>

<script lang="ts">
import { useCommonStore } from '@/stores/common';

export default {
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            commonStore: useCommonStore()
        }
    },
    methods: {
        hideModal() {
            setTimeout(() => {
                this.commonStore.closeModal()
            }, 13000);
        }
    },
    mounted() {
        this.hideModal();
    },
}
</script>

<style lang="scss">
.RedeemSuccess2 {
    .modal-content {
        max-width: 197px !important;

        img {
            max-width: 113px;
            max-height: 113px;
        }

        .title {
            margin: 0 0 8px;
            font-family: MicrosoftYaHeiSemibold;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            color: #252525;
        }

        .content_wrapper {
            padding: 4px 16px;
            border-radius: 8px;
            background-color: rgba(217, 29, 34, 0.06);
        }

        .content {
            margin: 0;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #d91d22;
        }
    }
}
</style>
<template>
    <header>
        <div :class="(navbarMenu) ? 'overlay' : ''" @click="expandMenu()"></div>
        <div :class="(agentStore.isLogin === true) ? 'top is_login' : 'top'">
            <div class="container">
                <a href="https://www.dooprimeaffiliates.com/"><img :src="imageUrl2 + 'Dooprime_new.png'" alt="Doo Prime"
                        class="logo" data-aos="fade-up" data-aos-duration="800"
                        :style="{ display: hideLogo ? 'none' : 'block' }"></a>

                <div class="navbar-menu right">
                    <div :class="(navbarMenu) ? 'userInfo nav-item nav-item-dropdown active' : 'userInfo nav-item nav-item-dropdown'"
                        data-aos="fade-up" data-aos-duration="800" v-if="agentStore.isLogin === true">
                        <a href="#" class="btn user" @click="expandMenu()">
                            <span>{{ agentStore.uuid }} <i></i></span>
                            <img
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/quarter-activity/agent/arrow-down.svg" />
                        </a>
                        <div class="nav-dropdown">
                            <!-- <a class="nav-link  user-center"> -->
                            <a :id="gtmButton.userCentre1" :class="`nav-link`" :href="commonStore.crmHomeUrl">
                                {{ $t("ib6.header.userCenter") }}
                            </a>
                            <hr>
                            <a :id="gtmButton.logout1" :class="`nav-link`" v-t="'ib6.header.logout'"
                                @click="logout()"></a>
                        </div>
                    </div>
                    <!-- <a v-else :href="loginUrl" class="btn-login" data-aos="fade-up" data-aos-duration="800">{{
                        $t("header.login")
                    }}</a> -->
                    <div v-else>
                        <a  v-if="currentPath === '2025Q2ibpromo'" href="https://campaign.dooprimeu1.com">
                            <button :id="gtmButton.login1" class="btn-red btn-login">
                                {{ $t("ib6.header.login") }}
                            </button>
                        </a>
                        <a v-else @click="this.commonStore.fetchCRMIdTags()"><button :id="gtmButton.login1"
                                class="btn-red btn-login">{{
                                    $t("ib6.header.login")
                                }}</button></a>
                        <!-- <a @click="getIdTagsInfo"><button :id="gtmButton.login1" class="btn-red btn-login">{{
                            $t("ib6.header.login")
                        }}</button></a> -->
                    </div>
                    <!--div class="nav-item nav-item-dropdown" data-aos="fade-up" data-aos-duration="800">
                        <div class="nav-dropdown-toggle" v-t="'header.language'"></div>
                        <div class="nav-dropdown">
                            <a :class="`nav-link`" v-for="locale in supportedLocales" :key="locale[0]" @click="switchLocale(locale)">
                                {{ locale[1] }}
                            </a>
                        </div>
                    </div -->
                </div>
            </div>
        </div>
    </header>
</template>

<script lang="ts">
import router from '../../router'
import { useAgentStore } from '../../stores/user'
import { useCommonStore } from '../../stores/common'
import { logout, getIdTags } from '../../api/auth'
import { useI18n } from 'vue-i18n';

const availableLang = {
    sc: 'zh',
    tc: 'tc',
    kr: 'kr',
    jp: 'jp',
}
export default {
    setup() {
        const i18n = useI18n();
        const agentStore = useAgentStore()
        const commonStore = useCommonStore()

        return {
            agentStore,
            commonStore,
            i18n,
        }
    },
    props: {
        gtmButton: {
            default: {
                'login1': `login_visitor`,
                'userCentre1': 'usercentre_1',
                'logout1': 'logout_1',
            },
        },
        hideLogo: {
            default: false
        }
    },
    computed: {
        currentPath(): string {
        return this.$route.path.split('/')[1] || '';
        }
    },
    data() {
        return {
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/",
            imageUrl2: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            crmLang: "sc",
            imgLang: this.$i18n.locale.toUpperCase(),
            registerPopupDisplay: false,
            isHeaderFloat: false,
            currentLanguage: "",
            supportedLocales: [
                ["sc", "简体中文"],
                ["tc", "繁體中文"],
                ["kr", "한국어"],
                ["jp", "日本語"],
            ],
            navbarMenu: false,
        }
    },

    methods: {

        scrollToTop() {
            window.scrollTo(0, 0);
        },
        switchLocale(locale) {
            if (this.$i18n.locale !== locale[0]) {
                this.$i18n.locale = locale[0];
                this.crmLang = locale[0];
                document.querySelector('#app')['class'] = 'lang-' + locale[0];
                router.push(`/${locale[0]}${this.$route.path.substring(3)}`)
            }
        },
        async logout() {
            const apiData = await logout().then((value) => {
                location.reload();
            });
        },

        expandMenu() {
            this.navbarMenu = !this.navbarMenu
        },

    },

    beforeMount() {
        this.scrollToTop();
    },
    mounted() {
        this.crmLang = availableLang[this.$i18n.locale];

        // this.bodyLang();
        // this.loginUrl = "https://user.dooprimed5.com/" + "&hl=" + availableLang[this.$i18n.locale]
    },
}
</script>

<style lang="scss" scoped>
$cdn: 'http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/';

header {
    // margin-bottom: -43px;

    .container {
        max-width: 1378px;
    }

    .logo {
        max-width: 135px;
        width: 100%;
        transition: 0.1s linear all;
    }

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        color: #000018;
        z-index: 1;
    }

    .btn-login {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 1.5px;
        text-align: center;
        color: #fff;
        padding: 7px 8px;
        border-radius: 4px;
        background-color: #d91d22;
        background-image: inherit;
        min-width: 80px;
        height: 36px;
        text-align: center;
        display: inline-block;
        box-shadow: inherit;
    }

    .top {
        position: absolute;
        top: 0;
        left: 0;
        padding: 24px 0px;
        width: 100%;
        z-index: 1;

        &.is_login {
            padding: 0;
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 600px;
        }

        .navbar-menu {
            >div {
                display: inline-block;

                &.userInfo {
                    margin-right: 15px;
                }
            }

            .user {

                span,
                img {
                    display: inline-block;
                }

                span {
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.71;
                    letter-spacing: 1.5px;
                    text-align: right;
                    color: #000018;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 90px;
                }

                img {
                    vertical-align: 4px;
                    margin: 0 0 0 5px;
                }
            }
        }

        .right {
            float: right;
        }

        .nav-dropdown-toggle {
            font-family: MicrosoftYaHei;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: 1.5px;
            text-align: right;
            color: #000018;
        }

        .nav-item-dropdown.active {
            .nav-dropdown {
                display: flex;
            }
        }

        .nav-item-dropdown {
            position: relative;
            cursor: pointer;

            // &:hover {
            //     .nav-dropdown {
            //         display: flex;
            //     }
            // }


            .nav-dropdown {
                display: none;
                position: absolute;
                top: calc(70%);
                right: 0;
                // padding: 12px 20px;
                background-color: #ffffff;
                flex-direction: column;
                border-radius: 7px;
                z-index: 1;
                box-shadow: 0 9px 13px 0 rgba(197, 197, 212, 0.6);
                background-color: #fafbff;

                hr {
                    background-color: #bebec8;
                    max-width: 88px;
                    width: 100%;
                    height: 0.3px;
                    margin: auto;
                    // margin: 7.8px 0;
                    border: 0.5px;
                }

                .nav-link {
                    // color: #000018;
                    margin-right: 0;
                    white-space: nowrap;
                    padding: 12px 20px;
                    text-decoration: none;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #575766;
                    // &:not(:last-child) {
                    //     margin-bottom: 24px;
                    // }

                    &.active,
                    &.router-link-active,
                    &:hover {
                        color: #d91d22;
                    }
                }
            }
        }
    }

    .event_period {
        @media only screen and (max-width: 375px) {
            text-align: left;
        }

        border-radius: 8px;
        background-color: #fddbdf;
        display: inline-block;
        padding: 4px 4px 6px 4px;
        margin-bottom: 28px;
        max-width: 600px;
        width: 100%;

        .event_period_text {
            // gap: 10px;
            min-width: 68px;
            padding: 2px 6px;
            border-radius: 4px;
            background-color: #fff;
        }

        .event_period_end_text {
            max-width: 600px !important;
            width: 100% !important;
        }

        span {
            // height: 19px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #ff2b30;
            text-align: center;

            &:last-child {
                max-width: 249px;
                width: 100%;
                display: inline-block;
                color: #ee0a24;
            }
        }
    }
}
</style>
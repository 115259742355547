<template>
    <div class="modal-content RewardList">
        <img class="bg_reward_top" :src="imageUrl + 'bg_reward_top.png'" alt="">
        <div class="card_title">
            {{ $t('ib6.modal.claimRewardModalTitle') }}
        </div>
        <!-- annual -->
        <div v-if="totalRewardApiData.claimablePrize.map(item => item.activityType == 5).includes(true)"
            class="content_card_wrapper">
            <p class="title">{{ $t('ib6.modal.claimRewardAnnualTitle') }}</p>
            <template v-for="(value, index) in totalRewardApiData.claimablePrize">
                <div v-if="value.activityType == 5" class="content_card">
                    <div>
                        <img v-if="value.activityType == 5 && value.prizeProduct == 'AA001'"
                            :src="imageUrl + 'trophy_bronze.png'" alt="">
                        <img v-if="value.activityType == 5 && value.prizeProduct == 'AA002'"
                            :src="imageUrl + 'trophy_sliver.png'" alt="">
                        <img v-if="value.activityType == 5 && value.prizeProduct == 'AA003'"
                            :src="imageUrl + 'trophy_gold.png'" alt="">
                        <div v-if="value.activityType == 5" class="content">
                            <p class="reward_title">{{ $t('ib6.modal.apiActivityAnnualGifts') }}</p>
                            <p class="reward_content">{{ $t(`ib6.modal.subTitle${value.prizeProduct}`) }}</p>
                        </div>
                    </div>
                    <p class="annual_amount">{{ $t(`ib6.modal.annual${value.prizeProduct}`) }}</p>
                </div>
            </template>
        </div>
        <!-- level -->
        <template v-if="totalRewardApiData.totalPendingPrize > 0">
            <p class="title">{{ $t('ib6.modal.claimRewardTitle') }}</p>
            <p class="total_reward_title">USD {{ totalRewardApiData.totalPendingPrize }}</p>
            <div class="dividend"></div>
            <div class="content_card_wrapper">
                <template v-for="(value, index) in totalRewardApiData.claimablePrize">
                    <div v-if="value.activityType < 5" class="content_card">
                        <div>
                            <img v-if="value.activityType == 0" :src="imageUrl + 'prize1.png'" alt="">
                            <img v-else-if="value.activityType == 1" :src="imageUrl + 'gold.png'" alt="">
                            <img v-else-if="value.activityType == 2 || value.activityType == 3 || value.activityType == 4"
                                :src="imageUrl + 'ranking.png'" alt="">
                            <div v-if="value.activityType == 0" class="content">
                                <p class="reward_title">{{ $t('ib6.modal.apiActivityGifts') }}</p>
                                <p class="reward_content">{{ $t(`ib6.modal.${value.eventType}`) }}</p>
                            </div>
                            <div v-else-if="value.activityType == 1" class="content">
                                <p class="reward_title">{{ $t('ib6.modal.apiActivityGoldGifts') }}</p>
                                <p class="reward_content">{{ $t(`ib6.modal.${value.eventType}`) }}</p>
                            </div>
                            <div v-else-if="value.activityType == 2" class="content">
                                <p class="reward_title">{{ $t('ib6.modal.apiActivityRankingEventInGold') }}</p>
                                <p class="reward_content">{{ $t(`ib6.modal.${value.eventType}`) }}</p>
                            </div>
                            <div v-else-if="value.activityType == 3" class="content">
                                <p class="reward_title">{{ $t('ib6.modal.apiActivityRankingTradingVolume') }}</p>
                                <p class="reward_content">{{ $t(`ib6.modal.${value.eventType}`) }}</p>
                            </div>
                            <div v-else-if="value.activityType == 4" class="content">
                                <p class="reward_title">{{ $t('ib6.modal.apiActivityRankingMIB') }}</p>
                                <p class="reward_content">{{ $t(`ib6.modal.${value.eventType}`) }}</p>
                            </div>
                        </div>
                        <p class="amount">USD {{ value.prizeAmountText }}</p>
                    </div>
                </template>
            </div>
        </template>
        <a id="Redeem_2" class="btn" @click="(isLoading) ? '' : claimPrize()"><span>{{ $t('ib6.modal.claimRewardBtnTitle')
        }}</span></a>
        <div class="modal-btn-close" @click="commonStore.closeModal()">
            <img :src="imageUrl2 + 'icon-close.svg'" />
        </div>
    </div>
</template>

<script lang="ts">
import { claimReward } from '@/api/getResult';
import { RewardClaimRoot } from '@/modal/RewardClaim';
import { TotalRewardData } from '@/modal/TotalReward';
import { numberWithCommas } from '@/service/general';
import { useCommonStore } from '@/stores/common'
export default {
    props: {
        totalRewardApiData: {
            default: {} as TotalRewardData,
        },
        getTotalRewardFunction: {},
        refreshFunction: {}
    },
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            imageUrl2: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/8-year-anniversary/8-year-ib/",
            commonStore: useCommonStore(),
            isLoading: false,

        }
    },
    methods: {
        async claimPrize() {
            try {
                this.isLoading = true;
                this.totalRewardApiData.giftTypeKey.claimType = 0

                const data: RewardClaimRoot = await claimReward(this.totalRewardApiData.giftTypeKey);
                if (data.code == 0) {
                    this.isLoading = false;

                    await this.getTotalRewardFunction();
                    await this.refreshFunction();
                    this.commonStore.openModal('RedeemSuccess2');
                } else {
                    this.isLoading = false;
                    this.commonStore.openModal('RedeemFail2');
                }
            } catch (error) {
                this.isLoading = false;
                this.commonStore.openModal('RedeemFail2');
            }

        }
    },

}
</script>

<style lang="scss">
.modal-content-wrapper {
    padding: 24px 16px !important;
}

.RewardList {
    .bg_reward_top {
        position: absolute;
        left: 50%;
        max-width: 311px;
        transform: translate(-50%, calc(-50% - 35px));
    }

    .card_title {
        padding: 8px 59px;
        transition: transform 1s, opacity 1s;
        transform: translate(-50%, calc(-50% - 36px));
        opacity: 1;
        position: absolute;
        // top: 31%;
        left: 50%;
        max-width: 311px;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        color: #fff;
    }

    p {
        margin: 0;
    }

    .title {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        color: #000;
    }

    .total_reward_title {
        margin-top: 4px;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #d91d22;
    }

    .dividend {
        margin: 15px auto 16px;
        width: 100%;
        height: 1px;
        background-color: #e3cfa1;
    }



    .content_card_wrapper {

        overflow-y: scroll;
        max-height: 250px;

        &::-webkit-scrollbar {
            width: 4px;
            height: 1px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #e3cfa1;
            border-radius: 5px;
        }

        .content_card {
            margin: 8px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 8px;
            box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.1),
                0 0 0 1px #ffebc6;
            border-image-source: linear-gradient(to bottom, #ffebc6 100%, #f2e0bf 4%);
            border-image-slice: 1;
            background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #ffebc6 100%, #f2e0bf 4%);
            background-origin: border-box;
            padding: 8px 16px;

            &:first-of-type {
                margin: 0 0 8px;
            }

            &:last-of-type {
                margin: 8px 0 32px;
            }

            div {
                display: flex;
                justify-content: space-between;
                align-items: center;

                img {
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: start;

                    .reward_title {
                        max-width: 105px;
                        margin-left: 9px;
                        font-size: 14px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: #252525;
                        // text-wrap: nowrap;
                    }

                    .reward_content {
                        margin-left: 9px;
                        font-size: 12px;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: #252525;
                        text-wrap: nowrap;
                    }
                }

            }

            .amount {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;
                color: #d91d22;
                text-wrap: nowrap;
            }

            .annual_amount {
                max-width: 70px;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;
                color: #d91d22;
            }
        }
    }


    .btn {
        display: inline-block;
        max-width: 180px;
        margin: 32px auto auto;
        border-radius: 21px;
        border-style: solid;
        border-width: 2px;
        border-image-source: linear-gradient(92deg, #f1353a 0%, #ffc1c2 23%, rgba(241, 180, 154, 0.57) 50%, #ffc1c2 72%, #f1353a 97%);
        border-image-slice: 0;
        background-image: linear-gradient(to bottom, #d91d22, #d91d22), linear-gradient(92deg, #f1353a 0%, #ffc1c2 23%, rgba(241, 180, 154, 0.57) 50%, #ffc1c2 72%, #f1353a 97%);
        background-origin: border-box;
        background-clip: content-box, border-box;

        span {
            display: inline-block;
            padding: 7px 16px;
            border-radius: 23px;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            color: #fff;
        }
    }

    .modal-btn-close {
        bottom: -50px !important;
        position: absolute !important;
    }
}
</style>
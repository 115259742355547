<template>
    <div class="modal-content PassEventRecord">

        <!-- <p class="title">{{ $t(`ib6.modal.${commonStore.previousEventName}`) }}</p> -->
        <p class="title">{{ $t(`ib6.modal.passEventTitle`) }}</p>
        <!-- <img class="title_bg"
                src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/pass_result_bg2.png" alt="">
            <img class="frame_bg"
                src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/pass_result_bg3.png" alt=""> -->
        <div class="content">
            <div class="rewardWrapper">
                <!-- <p class="passEventTitle">{{ $t(`ib6.modal.passEventTitle`) }}</p>
            <p class="passEventTitle">{{ $t(`ib6.modal.passEventTitle`) }}</p> -->
                <p>{{ $t('ib6.modal.levelTitle') }}</p>
                <p v-if="previousResultApiData.agentLevel > 0">{{ $t('ib6.modal.claimStatus1') }}</p>
                <p v-else>{{ $t('ib6.modal.claimStatus2') }}</p>
            </div>

            <div class="level_wrapper">
                <p v-if="previousResultApiData.agentLevel == 0">{{ $t('ib6.modal.failArchiveTitle') }}</p>
                <p v-if="previousResultApiData.agentLevel > 0">{{ $t('ib6.modal.archiveLevelTitle') }}</p>
                <img v-if="previousResultApiData.agentLevel > 0" class="bg_level"
                    :src="imageUrl + `LV${previousResultApiData.agentLevel}.png`" alt="">
            </div>
            <div class="prize_wrapper">
                <img :src="imageUrl + 'level_reward.png'" alt="">
                <p>{{ $t('ib6.modal.prizeArchiveTitle') }}</p>
            </div>
            <div class="wrapper">
                <div>
                    <p>{{ $t('ib6.monthlyStatistics.validCustomer') }}</p>
                    <p>
                        <span class="active">{{ previousResultApiData.qualifiedCustomersNumber }}</span>
                    </p>
                </div>
                <div>
                    <p>{{ $t('ib6.monthlyStatistics.topUp') }}</p>
                    <p>
                        <span class="active">{{ previousResultApiData.eventInGold }}</span>
                    </p>
                </div>
                <div>
                    <p>{{ $t('ib6.monthlyStatistics.trade') }}</p>
                    <p>
                        <span class="active">{{ previousResultApiData.tradingVolume }}</span>
                    </p>
                </div>

            </div>
            <p class="total_achieve_title">{{ $t('ib6.modal.totalAchieveTitle')
            }}{{ previousResultApiData.totalCommission }}</p>
            <div class="divider"></div>
            <div class="prize_wrapper">
                <img :src="imageUrl + 'gold_reward.png'" alt="">
                <p>{{ $t('ib6.modal.goldArchiveTitle') }}</p>
            </div>
            <div class="wrapper">
                <div>
                    <p>{{ $t('ib6.monthlyStatistics.gold') }}</p>
                    <p>
                        <span class="active">{{ previousResultApiData.goldAmount }}</span>
                    </p>
                </div>
            </div>
            <div v-if="Object.keys(previousAnnualResultApiData).length > 0" class="annual_wrapper">
                <div class="rewardWrapper">
                    <p>{{ $t('ib6.modal.annualTitle') }}</p>
                    <p v-if="Number(previousAnnualResultApiData.target) > 0 && Object.keys(isAnnualPrizeClaimed).length > 0">
                        {{
                            $t('ib6.modal.claimStatus1') }}</p>
                    <p
                        v-else-if="Number(previousAnnualResultApiData.target) > 0 && Object.keys(isAnnualPrizeClaimed).length == 0 && previousEventStatus == 'status5'">
                        已逾期</p>
                    <p v-else-if="Number(previousAnnualResultApiData.target) > 0">{{
                        $t('ib6.modal.claimStatus2') }}</p>
                    <p v-else>{{ $t('ib6.modal.claimStatus3') }}</p>
                </div>
                <p v-if="Number(previousAnnualResultApiData.target) > 0 && Object.keys(isAnnualPrizeClaimed).length == 0 && previousEventStatus == 'status5'"
                    class="expired_title">领奖时间截止至：2025年4月30日 23:59</p>
                <div v-if="Number(previousAnnualResultApiData.target) > 0" class="annual_prize_wrapper">
                    <p>{{ $t(`ib6.modal.annual${previousAnnualResultApiData.target}`) }}</p>
                    <img v-if="previousAnnualResultApiData.target == '1'" :src="imageUrl + 'trophy_bronze.png'" alt="">
                    <img v-if="previousAnnualResultApiData.target == '2'" :src="imageUrl + 'trophy_sliver.png'" alt="">
                    <img v-if="previousAnnualResultApiData.target == '3'" :src="imageUrl + 'trophy_gold.png'" alt="">
                </div>
                <div class="wrapper">
                    <div>
                        <p>{{ $t('ib6.monthlyStatistics.validCustomer') }}</p>
                        <p>
                            <span class="active">{{ previousAnnualResultApiData.totalQualifiedClient }}</span>
                        </p>
                    </div>
                    <div>
                        <p>{{ $t('ib6.monthlyStatistics.topUp') }}</p>
                        <p>
                            <span class="active">{{ previousAnnualResultApiData.totalInGold }}</span>
                        </p>
                    </div>
                    <div>
                        <p>{{ $t('ib6.monthlyStatistics.trade') }}</p>
                        <p>
                            <span class="active">{{ previousAnnualResultApiData.totalTransaVolume }}</span>
                        </p>
                    </div>

                </div>
                <p class="total_achieve_title">{{ $t('ib6.modal.totalAnnualAchieveTitle')
                }} {{ previousResultApiData.totalCommission }}</p>
            </div>

        </div>
        <div class="modal-btn-close" @click="commonStore.closeModal()">
            <img :src="imageUrl2 + 'icon-close.svg'" />
        </div>
    </div>
</template>

<script lang="ts">
import { useCommonStore } from '@/stores/common'
import { ResultData } from '@/modal/Result'
import { AnnualRewardData } from '@/modal/AnnualReward';
import { apiActivityObject } from '@/modal/ClaimedPrizeRecord';

export default {
    props: {
        previousResultApiData: {
            default: {} as ResultData,
        },
        previousAnnualResultApiData: {
            default: {} as AnnualRewardData,
        },
        isAnnualPrizeClaimed: {
            default: {} as apiActivityObject,
        },
        previousEventStatus: {
            default: ''
        },


    },
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            imageUrl2: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/8-year-anniversary/8-year-ib/",
            commonStore: useCommonStore(),
            isLoading: false,

        }
    },
    methods: {

    },

}
</script>

<style lang="scss" scoped>
// .modal-content-wrapper {
//     padding: 24px 16px !important;
// }


.PassEventRecord {
    padding: 23px 30px !important;
    width: 100% !important;
    max-width: 360px !important;
    background-image: url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/pass_result_bg.png') !important;
    background-size: 100% 90%;
    // background-size: contain;
    background-repeat: no-repeat;
    box-shadow: inherit !important;

    // @media only screen and (max-width: 375px) {

    //     background-size: contain;
    // }

    .title {
        position: relative;
        z-index: 2;
        margin-top: 13px;
        // margin-left: 7px;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }

    .content {
        position: relative;


        overflow-y: auto;
        max-height: 450px;

        &::-webkit-scrollbar {
            width: 3px;
            height: 1px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #e3cfa1;
            border-radius: 5px;
        }




        p {
            margin: 0;
        }



        .title_bg {
            position: absolute;

            transform: translate(32%, 0%);
            width: 168px;
            top: 0px;
            left: 0;
            z-index: 1;
        }

        .frame_bg {
            width: 360px !important;
            height: 92%;
            margin-left: -5px;
            position: absolute;
            transform: translate(0%, 0%);
            top: 0px;
            left: 0px;
            z-index: 1;
        }


        .rewardWrapper {
            margin-top: 17px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-image: linear-gradient(to bottom, #f9f9f9, rgba(255, 235, 235, 0.96));
            border: solid 1px rgba(217, 29, 34, 0.15);
            padding: 6px 8px;



            p {
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #000;

                &:last-child {
                    font-size: 12px;
                    color: #d91d22;
                }
            }
        }


        .expired_title {
            margin: 7px 0 0 0;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: left;
            color: #d91d22;
        }

        .level_wrapper {
            margin: 23px 0 19px;
            margin-top: 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #252525;
                text-wrap: nowrap;
            }

            .bg_level {
                width: 52px;
            }
        }

        .prize_wrapper {
            margin-bottom: 8px;
            display: flex;
            align-items: center;

            p {
                margin-left: 16px;
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #252525;
            }
        }

        .annual_prize_wrapper {
            margin-top: 9px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #252525;
            }

            img {
                width: 42.9px;
                height: 39.4px;
                object-fit: contain;
            }
        }

        .total_achieve_title {
            margin-top: 4px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: left;
            color: #000;
        }

        .wrapper {
            div {
                display: flex;
                justify-content: space-between;
                margin-bottom: 4px;

                p {
                    display: flex;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.67;
                    letter-spacing: normal;
                    text-align: left;
                    color: #878793;

                    span {
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.67;
                        letter-spacing: normal;
                        text-align: left;
                        color: #252525;


                    }
                }
            }
        }

        .divider {
            margin: 15px 0 16px;
            border-top: 1px solid #c39654;
        }
    }

    .modal-btn-close {
        margin-top: 40px;
    }
}
</style>
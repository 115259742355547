<template>
    <Navbar :hideLogo="true" />
    <template v-for="item in ossData">
        <div>
            <a :href="item.url" target="_blank"><img :id="item.image_id" :src="item.image_url" /></a>
        </div>
    </template>
</template>
  
<script lang="ts">
import { promotionConfig } from "@/api/promotionConfig";
import Navbar from '@/components/Header/Navbar.vue'
export default {

    name: 'HomeView',
    components: {
        Navbar,
    },
    data() {
        return {
            ossData: []
        }
    },
    methods: {
        async getPromotion() {

            const { data } = await promotionConfig();
            this.ossData = data['default'];
            // const countryCode = this.commonStore.agentLocation.toUpperCase();
            // if (data.hasOwnProperty(countryCode)) {
            //   this.ossData = data[countryCode];
            // } else {
            //   this.ossData = data['default'];
            // }
        }
    },
    mounted() {
        this.getPromotion();
    },
}
</script>
  
<style scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

img {
    width: 600px;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}
</style>
  
import { createI18n } from "vue-i18n";

const defaultLocale = "sc";
const localesSupport = ["sc", "tc", "en", "jp", "kr", "th"];

function getDatetimeNow() {
  return `?${new Date().toISOString()}`;
}

const scUrl = `https://multilingual-gateway.finpoints.tech/multilingual-translation-system/EventIB/1.0.0/zh_cns.json?11`;
const tcUrl = `https://multilingual-gateway.finpoints.tech/multilingual-translation-system/EventIB/1.0.0/zh_cnt.json?11`;

const localesMap = new Map([
  [localesSupport[0], scUrl],
  [localesSupport[1], tcUrl],
]);

async function loadLocaleMessages(locale: string) {
  if (!localesSupport.includes(locale)) {
    console.warn(
      `Locale ${locale} not supported, defaulting to ${defaultLocale}`
    );
    locale = defaultLocale;
  }

  try {
    const jsonUrl = localesMap.get(locale);
    const response = await fetch(jsonUrl!);

    if (!response.ok) {
      console.warn(
        `Failed to fetch ${locale} locale, falling back to local fallback`
      );
      return (await import(`./locales/${defaultLocale}.json`)).default;
    }

    return await response.json();
  } catch (error) {
    console.error(`Error loading locale ${locale}:`, error);
    return (await import(`./locales/${defaultLocale}.json`)).default;
  }
}

export async function createDynamicI18nInstance(locale: string) {
  const messages = {
    [locale]: (await loadLocaleMessages(locale)).ib_event.ib_event,
  };

  const i18n = createI18n({
    locale,
    fallbackLocale: defaultLocale,
    legacy: false,
    messages,
  });

  // Extend the `$t` method to handle excluded paths
  const originalT = i18n.global.t;
  const excludedPaths = ["/testmts", "/sc/testmts", "/tc/testmts"];

  i18n.global.t = function (key: string, ...args: any[]) {
    // Check the current path from the Vue Router
    const currentPath = window.location.pathname; // Replace with `useRoute` if using Composition API

    if (excludedPaths.includes(currentPath)) {
      // Return the raw key or a default value for excluded paths
      return key;
    }

    // Use the original `$t` for all other cases
    return originalT(key, ...args);
  };

  return i18n;
}

export default async function setupI18n() {
  const locale = import.meta.env.VITE_APP_I18N_LOCALE || defaultLocale;
  return await createDynamicI18nInstance(locale);
}

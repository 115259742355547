import api from "./config";

const baseUrl = "/validDomain";

export const validDomain = async () => {
  let filterUrl = [];
  let success = false; // Flag to track if a valid URL is found
  let retryCount = 0; // Track the number of retries
  const maxRetries = 10; // Maximum number of retries
  let validUrl = ""; // Will hold the valid URL if found

  while (!success && retryCount < maxRetries) {
    retryCount++;
    // console.log(
    //   `Attempt ${retryCount}/${maxRetries}: Fetching valid domains...`
    // );

    // Make the API call using the current filterUrl.
    let response;
    try {
      response = await api({
        method: "get",
        url: baseUrl, // baseUrl here may be a default URL for the API
        params: { filterUrl },
      });
    } catch (e) {
      console.error("API call failed:", e);
      response = e;
    }

    const data = response?.data;
    if (data?.data?.length > 0) {
      // Assuming the response structure provides an array of domains at data.data[0].link
      const domainLinks = data.data[0].link;
      let tempFilter = [];

      // Try each domain link until a valid one is found
      for (const link of domainLinks) {
        try {
          const preFlightUrl = await fetch(link, {
            headers: { "Access-Control-Allow-Origin": "*" },
            mode: "no-cors",
          });

          // Check if the URL is accessible (status 0 for no-cors or 200 for success)
          if (preFlightUrl.status === 0 || preFlightUrl.status === 200) {
            validUrl = `https://${new URL(link).hostname}`;
            success = true;
            break; // Exit the loop since a valid URL has been found
          } else {
            tempFilter.push(link);
          }
        } catch (error) {
          console.error("Error fetching:", link, error);
          tempFilter.push(link);
        }
      }
      // Update filterUrl with the links that failed the check, for the next retry iteration.
      filterUrl = tempFilter;
    }

    // If no valid domain is found and we've either run out of filter options or exceeded retries, break.
    if (!success && (filterUrl.length === 0 || retryCount >= maxRetries)) {
      console.warn(
        "No valid domains found after maximum retries. Using default URL."
      );
      break;
    }
  }

  return success ? validUrl : null;
};

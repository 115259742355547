export const getCookie = (name: string) => {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const setCookie = (
  key: any,
  value: any,
  expiresDay: any,
  path: any,
  domain: any,
  secure: any
) => {
  if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key))
    return false;
  var setArr = [encodeURIComponent(key) + "=" + encodeURIComponent(value)];
  if (domain) setArr.push("domain=" + domain);
  if (path) setArr.push("path=" + path);
  if (secure) setArr.push("secure");
  if (expiresDay) {
    var expires = new Date();
    expires.setTime(expires.getTime() + expiresDay * 24 * 60 * 60 * 1000);
    setArr.push("expires=" + expires.toUTCString());
  } else if (expiresDay === 0) {
    setArr.push("expires=Thu, 01 Jan 1970 00:00:00 GMT");
  }
  document.cookie = setArr.join("; ");
  return true;
};

export const clearCache = (): void => {
  // Clear all cookies by splitting the cookie string and setting each cookie's expiration to the past
  document.cookie.split(";").forEach((cookie) => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim();
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  });

  // Clear localStorage
  localStorage.clear();

  // Clear sessionStorage
  sessionStorage.clear();
};

export const getUtmPath = () => {
  let searchStr = (window.location.search || window.location.href).replace(
    /^.*\?/,
    ""
  );
  let searchArr = searchStr.split("&");
  let utmPath: any = {};
  for (var i = 0; i < searchArr.length; i++) {
    var s = searchArr[i];
    var keyValue = s.split("=");
    if (keyValue[0] != searchStr) {
      utmPath[keyValue[0]] = keyValue[1];
    }
  }
  return utmPath;
};

export const getCollectionId = () => {
  let collectionId = getCookie("collection_id");
  if (!collectionId) {
    var gid = getCookie("_ga");
    if (gid) {
      collectionId = gid.substring(6);
    } else {
      collectionId = "";
      for (var i = 0; i < 9; i++) {
        collectionId += Math.floor(Math.random() * 10);
      }
      collectionId += "." + (Date.now() + "").substring(0, 10);
    }
    setCookie("collection_id", collectionId, 365, "/", "", "");
  }
  return collectionId;
};
